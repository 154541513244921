// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

import OutLink from '@bodhi-project/components/lib/OutLink'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Video from '../components/Video'
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Early Years Learning',
  nakedPageSlug: 'early-years-learning',
  pageAbstract: 'Content being added at the moment.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 style={{ marginBottom: 0 }}>Programmes Crafted for Young Learners</h1>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <p>
          Cambridge Sparkling Stars is an exciting aspiration with a mission
          that children are worthy of deepest respect. They are to be provided
          with opportunities to discover their abilities in every area of
          knowledge and most importantly, be given freedom to explore at their
          own pace and in their own way. We believe that each child is capable
          of finding thousand ways of doing and understanding things, parents,
          school and teachers simply need to challenge them to excel. Sparkling
          Stars Playschools are to provide learning through loving, trusting and
          respectful relationships, and through discussion, exploration and
          play. With inquiry as goal, we've built this programmes with these
          ideals in mind:
        </p>
        <ul className="mask-p">
          <li>Students as shareholders, decision takers and communicators</li>
          <li>Teachers as Co-learners, Carers and Guides</li>
          <li>Parents as partners</li>
          <li>Earth as a Home</li>
          <li>World as a Family</li>
        </ul>
        <h2 style={{ marginBottom: 0 }}>Pedagogy</h2>
        <p>
          <strong>Framework for early learning</strong>
        </p>
        <p>
          Children develop and learn in different ways and at different rates.
          The framework covers the education and care of all children in early
          years provision, including children with special educational needs and
          disabilities. Children learn and develop well in enabling
          environments, in which their experiences respond to their individual
          needs and there is a strong partnership between practitioners and
          parents and/or carers. There are seven areas of learning and
          development that must shape educational programmes in early years
          settings. All areas of learning and development are important and
          inter-connected. Three areas are particularly crucial for igniting
          children's curiosity and enthusiasm for learning, and for building
          their capacity to learn, form relationships and thrive. Our pedagogy
          revolves around these understandings.
        </p>
        <h3>Communication & Language Development Programme</h3>
        <p>
          This programme involves giving children opportunities to experience a
          rich language environment; to develop their confidence and skills in
          expressing themselves; and to speak and listen in a range of
          situations.
        </p>
        <h3>Physical Development Programme</h3>
        <p>
          This programme involves providing opportunities for young children to
          be active and interactive; and to develop their co-ordination,
          control, and movement. Children must also be helped to understand the
          importance of physical activity, and to make healthy choices in
          relation to food.
        </p>
        <h3>Personal, Social & Emotional Development Programme</h3>
        <p>
          This programme involves helping children to develop a positive sense
          of themselves, and others; to form positive relationships and develop
          respect for others; to develop social skills and learn how to manage
          their feelings; to understand appropriate behaviour in groups; and to
          have confidence in their own abilities.
        </p>
        <h3>Literacy Development Programme</h3>
        <p>
          This programme involves encouraging children to link sounds and
          letters and to begin to read and write. Children must be given access
          to a wide range of reading materials (books, poems, and other written
          materials) to ignite their interest.
        </p>
        <h3>Mathematics Programme</h3>
        <p>
          This programme involves providing children with opportunities to
          develop and improve their skills in counting, understanding and using
          numbers, calculating simple addition and subtraction problems; and to
          describe shapes, spaces, and measures.
        </p>
        <h3>Understanding the World Programme</h3>
        <p>
          This programme involves guiding children to make sense of their
          physical world and their community through opportunities to explore,
          observe and find out about people, places, technology and the
          environment.
        </p>
        <h3>Expressive Arts & Design Programme</h3>
        <p>
          This programme involves enabling children to explore and play with a
          wide range of media and materials, as well as providing opportunities
          and encouragement for sharing their thoughts, ideas and feelings
          through a variety of activities in art, music, movement, dance,
          role-play, and design and technology.
        </p>
      </Col>
      <Col sm={24} md={8}>
        <Video
          url="https://www.youtube.com/watch?v=bMdMxu-cn20"
          className="mask-p"
        />
        <p>
          Every child deserves the best possible start in life and the support
          that enables them to full their potential. Children develop quickly in
          the early years and a child's experiences between birth and age five
          have a major impact on their future life chances.
        </p>
        
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
